.otp_input_box {
  width: 3.2rem !important;
  height: 3.2rem;
  margin: 1rem 0.5em 0 0.5rem;
  color: black;
  border-radius: 5px;
  outline: none;
  border: 1.5px solid rgb(179, 176, 176);
}

.otp_input_box:focus {
  border: 2px solid rgb(121, 120, 120);
}

.verify-otp {
  width: 100%;
}

@media (min-width: 600px) and (max-width:1200px) {
  .otp_input_box {
    width: 2.5rem !important;
    height: 35px;
    margin: 0 0.5em;
  }
}

@media screen and (max-width: 600px) {
  .otp_input_box {
    width: 2.5rem !important;
    height: 35px;
    margin: 0 0.5em;
  }
}

.verifyemail_chip_cursor>svg {
  cursor: default !important;
}

.otp_input_box::placeholder {
  /* Customize placeholder styles */
  color: #a7a5a5; /* Placeholder text color */
  font-style: italic; /* Placeholder font style */
  text-decoration: underline;
}